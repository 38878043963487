.blog-post-body{
    background-color: #f9fbfe;
    padding: 16vh 15vh 16vh 15vh;
    text-align: center;
}


#grid {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    grid-template-areas: "a a a";
}

.text-decoration{
    text-decoration: none;
}

.blog-posts{
      width: 90%;
      margin-bottom: 10vh;
      border: none;
      background-color: #f0f3ff;
      position: relative;
      text-decoration: none;
      transition: all 0.8s;
}

.blog-posts:hover{
    scale: 1.1;

}

.blog-posts-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
color: #303030;
text-align: left;
}

.blog-post-author{
    font-weight: 400;
font-size: 14px;
line-height: 28px;
color: #3C6AEA;
margin-top: 2vh;
text-align:left;
}

.blog-card-body{
    padding: 2vh 3vh 3vh 3vh;
}

.blog-post-text{
font-weight: 400 ;
font-size: 14px ;
line-height: 28px ;
color: #ACACAC;
text-align: left;
}

.blog-post-date{
    position: absolute;
    background-color: #3C6AEA;
    left: 2vh;
    top: 2vh;
    border-radius: 1vh;
}

.blog-date{
    font-weight: 600;
font-size: 27px;
line-height: 50px;
color: #fff;
width: 6vh;
text-align: center;
}

.blog-month{
    background-color: #24408C;
    font-weight: 600;
font-size: 13px;
line-height: 24px;
}

@media(max-width: 576px){
    .blog-post-body{
        background-color: #f9fbfe;
        padding: 16vh 5vh 16vh 5vh;
    }


    #grid {
        grid-template-areas: "a";
    }


}

@media(min-width: 576px){
    .blog-post-body{
        background-color: #f9fbfe;
        padding: 16vh 5vh 16vh 5vh;
    }


    #grid {
        grid-template-areas: "a";
    }

}

@media(min-width: 768px){
    .blog-post-body{
        background-color: #f9fbfe;
        padding: 16vh 5vh 16vh 5vh;
    }


    #grid {
        grid-template-areas: "a a";
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .blog-post-body{
        background-color: #f9fbfe;
        padding: 16vh 15vh 16vh 15vh;
        text-align: center;
    }


    #grid {
        display: grid;
        grid-auto-rows: auto;
        grid-auto-columns: auto;
        grid-template-areas: "a a a";
    }


}
