.blog-details-background{
    background-color: #f9fbfe;
    padding: 18vh 15vh 18vh 15vh;
}

.blog-details-body{
    background-color: #fff;
    display: block;
    border-radius: 2vh;
    height: auto;
}

.blog-details-text-place{
    padding: 2vh 4vh 2vh 4vh;
}

.blog-details-img{
    max-width: 80%;
    border-radius: 2vh;
}

.blog-details-img-place{
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-details-author{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #ACACAC;
}

.blog-details-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-top: 3vh;
    margin-bottom: 3vh;
    text-align: justify;
}

.blog-details-tags-title{
    font-weight: 700;
font-size: 20px;
line-height: 32px;
color: #303030;
}

.tag-body-place{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.tag-body{
    border-radius: 1px solid #acacac;
    margin: 0;
    padding: 0;
}

@media(max-width: 576px){
    .blog-details-background{
    padding: 18vh 2vh 18vh 2vh;
    }
    .blog-details-img{
    max-width: 100%;
    }


}

@media(min-width: 576px){
    .blog-details-background{
    padding: 18vh 2vh 18vh 2vh;
    }
    .blog-details-img{
    max-width: 100%;
    }

}

@media(min-width: 768px){


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .blog-details-background{
    background-color: #f9fbfe;
    padding: 18vh 15vh 18vh 15vh;
}

.blog-details-body{
    background-color: #fff;
    display: block;
    border-radius: 2vh;
    height: auto;
}

.blog-details-text-place{
    padding: 2vh 4vh 2vh 4vh;
}

.blog-details-img{
    max-width: 80%;
    border-radius: 2vh;
}

.blog-details-img-place{
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-details-author{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #ACACAC;
}

.blog-details-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.blog-details-tags-title{
    font-weight: 700;
font-size: 20px;
line-height: 32px;
color: #303030;
}


.tags-title{
    font-weight: 700;
font-size: 20px;
line-height: 32px;
}
.tag-body-place{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.tag-body{
    border: 1px solid #acacac;
    border-radius: 1vh;
    padding: 1vh;
    width: auto;
}


}
