.client-section-body{
    background-color: #f0f3ff;
    padding: 12vh 15vh 15vh 15vh;
    text-align: center;
}

.client-section-sub{
    font-weight: 600;
font-size: 18px;
line-height: 32px;
color: #3C6AEA;
}

.client-section-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
color: #303030;
margin-bottom: 3vh;
}

.client-margin{
    margin-top: 6vh;
    overflow:hidden;
}

.client-img-place{
    height: 80px;
    width: 90px;
}

.client-img-place img{
    max-width: 100%;
    max-height: 100%;
    filter: invert(26%) sepia(1%) saturate(0%) hue-rotate(165deg) brightness(95%) contrast(86%);
}



@media(max-width: 576px){
    .client-section-body{
        padding: 12vh 0 15vh 0;
    }

}

@media(min-width: 576px){
    .client-section-body{
        padding: 12vh 0 15vh 0;
    }


}

@media(min-width: 768px){


}

@media(min-width:992px){


}

@media(min-width:1200px) {

    .client-section-body{
        background-color: #f0f3ff;
        padding: 20vh 15vh 20vh 15vh;
        text-align: center;
    }


}
