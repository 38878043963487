.portfolio-hero{
    padding: 20vh 15vh 20vh 15vh;
    background-color: #f9fbfe;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.portfolio-hero-link{
    margin-bottom: 2vh;
    color: #acacac;
    font-weight: 400;
font-size: 18px;
line-height: 32px;
}

.hero-link{
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    text-decoration: none;
    color: #3C6AEA;
    margin-right: 2vh;
}

.hero-link:hover{
    color: 303030;
}

.portfolio-hero-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
color: #303030;
}

.portfolio-hero-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6E6E6E;
    width: 40%;
}

@media(max-width: 576px){
    .portfolio-hero{
        padding: 20vh 5vh 20vh 5vh;
    }
    .portfolio-hero-text{
        width: 90%;
    }


}

@media(min-width: 576px){
    .portfolio-hero{
        padding: 20vh 5vh 20vh 5vh;
    }
    .portfolio-hero-text{
        width: 90%;
    }


}

@media(min-width: 768px){


}

@media(min-width:992px){
    .portfolio-hero{
        padding: 20vh 5vh 10vh 5vh;
    }
    .portfolio-hero-text{
        width: 50%;
    }


}

@media(min-width:1200px) {
    .portfolio-hero{
        padding: 20vh 15vh 20vh 15vh;
        background-color: #f9fbfe;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .portfolio-hero-link{
        margin-bottom: 2vh;
        color: #acacac;
        font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    }

    .hero-link{
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        text-decoration: none;
        color: #3C6AEA;
        margin-right: 2vh;
    }

    .hero-link:hover{
        color: 303030;
    }

    .portfolio-hero-title{
        font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #303030;
    }

    .portfolio-hero-text{
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #6E6E6E;
        width: 40%;
    }


}
