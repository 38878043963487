@import url('https://fonts.googleapis.com/css?family=Poppins:wght@400,500,600,700&display=swap');

*{
  box-sizing: border-box;
  margin:0; padding:0;
}
html{
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;

}
body{
  margin: 0;
  font-family: 'Poppins', sans-serif;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f9fbfe;
}
