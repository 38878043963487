.home-hero{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/home.png);
    width: 100%;
    height: 90vh;
    color: #fff;
    padding: 25vh 20vh 25vh 20vh;
}

.home-sub{
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    margin-top: 10vh;
    width: 60%;
}

.home-title{
    font-weight: 600;
    font-size: 38px;
    line-height: 57px;
    width: 60%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    height: 50vh;
}

.home-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    width: 60%;
    margin-bottom: 3vh;
}

.learn-more{
    padding: 1.5vh 3vh 1.5vh 3vh;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    background-color: #3C6AEA;
    border: 1px solid #3C6AEA;
    transition: all 0.8s;
}

.learn-more:hover{
    background-color: #101010;
    color: #3C6AEA;
}

.home-hero-bottom{
    color: #1c1c1c;
    height: 100px;
    margin: auto;
    align-items: center;
    display: flex;
    padding-left: 15vh;
    padding-right: 15vh;
    background-color: #3C6AEA;
    justify-content: space-between;
}

.home-bottom-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #fff;
    align-items: flex-start;
}

.home-contact{
    padding: 1.5vh 4vh 1.5vh 4vh;
    background-color: #101010;
    border: 1px solid #101010;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
}

.home-contact:hover{
    background-color: #fff;
    color: #101010;
}

@media(max-width: 576px){

    .home-hero{
    height: auto;
    padding: 15vh 5vh 25vh 5vh;
    background-position: center bottom;
    }
    .home-title{
        width: 100%;
        margin-top: 2vh;
        margin-bottom: 2vh;
        height: 35vh;
    }
    .home-text{
    width: 100%;
    }
    .home-hero-bottom{
        height: auto;
        padding: 3vh 5vh 3vh 5vh;
        display: block;
    }
    .home-bottom-title{
        text-align: left;
    }


    .home-contact{
        margin-top: 2vh;
    }


}

@media(min-width: 576px){

    .home-hero{
    height: auto;
    padding: 15vh 5vh 25vh 5vh;
    background-position: center bottom;
    }
    .home-title{
        width: 100%;
        margin-top: 2vh;
        margin-bottom: 2vh;
        height: 20vh;
    }
    .home-text{
    width: 100%;
    }
    .home-hero-bottom{
    height: auto;
    padding: 2vh 5vh 2vh 5vh;
    }
    .home-contact{
    padding: 1vh 4vh 1vh 4vh;
    background-color: #101010;
    border: 1px solid #101010;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    }


}

@media(min-width: 768px){
    .home-title{
        height: 15vh;
    }


}

@media(min-width:992px){
    .home-title{
        height: 12vh;
    }


}

@media(min-width:1200px) {
    .home-hero{
        background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/home.png);
        width: 100%;
        height: auto;
        color: #fff;
        padding: 35vh 20vh 25vh 20vh;
    }

    .home-sub{
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        margin-top: 10vh;
        width: 60%;
    }

    .home-title{
        font-weight: 600;
        font-size: 38px;
        line-height: 57px;
        width: 60%;
        margin-top: 2vh;
        margin-bottom: 2vh;
        height: 20vh;
    }

    .home-text{
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        width: 60%;
        margin-bottom: 3vh;
    }

    .learn-more{
        padding: 1.5vh 3vh 1.5vh 3vh;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        background-color: #3C6AEA;
        border: 1px solid #3C6AEA;
        transition: all 0.8s;
    }


    .home-hero-bottom{
        color: #1c1c1c;
        height: 100px;
        margin: auto;
        align-items: center;
        display: flex;
        padding-left: 15vh;
        padding-right: 15vh;
        background-color: #3C6AEA;
        justify-content: space-between;

    }

    .home-bottom-title{
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #fff;
        align-items: flex-start;
    }

    .home-contact{
        padding: 1.5vh 4vh 1.5vh 4vh;
        background-color: #101010;
        border: 1px solid #101010;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
    }



}
