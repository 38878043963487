.navbar-background{
    padding: 6vh 15vh 6vh 15vh;
    background: none;
    position: static;
}

.nav-placement .nav-link{
    margin-left: 2vh;
    color: #fff;
}

.navigation-navigation-place{
    width: 10%;
    object-contain: fill;
}

.nav-try-1{
    background: url(/src/assets/logo_1.png) no-repeat;
    height: 5vh;
   width: 100%;
   background-size: contain;
   background-position: center center;
}

.logo-navigation{
    max-width: 100%;
}

.burger-color{
    color: #fff !important;
    background-color: #fff !important;
}

.navbar-toggler{
    border: none;
}

@media(max-width: 576px){
    .navbar-background{
        padding: 3vh 5vh 3vh 5vh;
        background-color: #101010;
        position: static;
    }
    .navigation-navigation-place{
        width: 35%;
        object-contain: fill;
    }
    .nav-placement .nav-link{
        padding-left: 3vh;
        color: #fff;
        background-color: #101010;
    }
    .burger-color{
        color: #fff !important;
        background-color: #101010 !important;
    }
}

@media(min-width: 576px){
    .navbar-background{
        padding: 3vh 5vh 3vh 5vh;
        background: none;
        position: static;
        background-color: #101010;
    }
    .navigation-navigation-place{
        width: 25%;
        object-contain: fill;
    }
    .nav-placement .nav-link{
        padding-left: 3vh;
        color: #fff;
        background-color: #101010;
    }
    .burger-color{
        color: #fff !important;
        background-color: #101010 !important;
    }

}

@media(min-width: 768px){
    .navbar-background{
        padding: 2vh 5vh 2vh 5vh;
        background: none;
        position: static;
        background-color: #101010;
    }

    .nav-placement .nav-link{
        margin-left: 2vh;
        background-color: #101010;
    }

    .navigation-navigation-place{
        width: 15%;
        object-contain: fill;
    }


}

@media(min-width:992px){
    .navbar-background{
        background:none;
        position: absolute;
    }
    .nav-placement .nav-link{
        margin-left: 2vh;
        color: #fff !important;
        background: none;
    }
    .navigation-navigation-place{
        width: 15%;
        object-contain: fill;
    }


}

@media(min-width:1200px) {
    .navbar-background{
        padding: 6vh 15vh 6vh 15vh;
        background: none;
        position: absolute;
    }

    .nav-placement .nav-link{
        margin-left: 2vh;
        color: #fff;
    }

    .navigation-navigation-place{
        width: 10%;
        object-contain: fill;
    }


    .logo-navigation{
        max-width: 100%;
    }

    .burger-color{
        color: #fff !important;
        background-color: #fff !important;
    }

    .navbar-toggler{
        border: none;
    }


}
