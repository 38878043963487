.servicesdetails-hero{
    padding: 25vh 15vh 25vh 15vh;
    background:linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_1.png) no-repeat center fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.servicesdetails-hero-title{

    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: #fff;
    width: 20%;
}

.process-section2-body{
    background-color: #f0f3ff;
    padding: 15vh 15vh 15vh 15vh;
}

.process-section2-img-place{
    background: url(/src/assets/agile.png) no-repeat;
     height: 50vh;
    width: 100%;
    background-size:cover;
    background-position: center center;
}

.process-section2-img-place1{
    background: url(/src/assets/card_1.jpeg) no-repeat;
     height: 50vh;
    width: 100%;
    background-size:cover;
    background-position: center center;
}

.process-section2-img-place2{
    background: url(/src/assets/card_2.jpeg) no-repeat;
     height: 50vh;
    width: 100%;
    background-size:cover;
    background-position: center center;
}

.process-section2-img-place3{
    background: url(/src/assets/card_3.jpeg) no-repeat;
     height: 50vh;
    width: 100%;
    background-size:cover;
    background-position: center center;
}

.color-gray{
    color: #6E6E6E;
}





.process-section3-img-place{
    background: url(/src/assets/details.png) no-repeat;
    min-height: 60vh;
    width: 100%;
    background-size:cover;
    background-position: center center;
}

.process-section2-content{
    display: flex;
    flex-direction: column;
}

.process-section2-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
}

.process-section2-text{
    font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #6E6E6E;
text-align: justify;
margin-top: 2vh;
margin-bottom: 2vh;
}

.gray{
    color: #6E6E6E;
}


.servicesdetails-section2-body{
    padding: 10vh 15vh 10vh 15vh;
}

.tab-value1{
    width: 90%;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6E6E6E;
}
.value-icon1{
    color: #3C6AEA;;
    font-size: 30px;
    display: block;}

.bold{
    font-weight: 700;
}

.tab-value1-place{
    display: flex;
}






@media(max-width: 576px){

    .servicesdetails-hero{
        padding: 25vh 5vh 25vh 5vh;
    }

    .servicesdetails-hero-title{
        width: 40%;
    }

    .process-section2-body{
        background-color: #f0f3ff;
        padding: 15vh 5vh 15vh 5vh;
    }

    .servicesdetails-section2-body{
        padding: 10vh 5vh 10vh 5vh;
    }
    .process-section3-img-place{
        margin-top: 3vh;
    }


}

@media(min-width: 576px){
    .process-section2-body{
        padding: 10vh 5vh 10vh 5vh;
    }
       .servicesdetails-hero{
        padding: 25vh 5vh 25vh 5vh;
    }

    .servicesdetails-hero-title{
        width: 40%;
    }
    .servicesdetails-section2-body{
        padding: 10vh 5vh 10vh 5vh;
    }
    .process-section3-img-place{
        margin-top: 3vh;

    }

}

@media(min-width: 768px){
       .servicesdetails-hero{
        padding: 25vh 5vh 25vh 5vh;
    }

    .servicesdetails-hero-title{
        width: 30%;
    }


}

@media(min-width:992px){
       .servicesdetails-hero{
        padding: 25vh 5vh 25vh 5vh;
    }

    .servicesdetails-hero-title{
        width: 20%;
    }
    .process-section3-img-place{
        margin: 0;
        min-height: 40vh;
    }


}

@media(min-width:1200px) {
    .servicesdetails-hero{
        padding: 25vh 15vh 25vh 15vh;
        background:linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_1.png) no-repeat center fixed;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .servicesdetails-hero-title{

        font-weight: 600;
        font-size: 27px;
        line-height: 40px;
        color: #fff;
        width: 20%;
    }

    .process-section2-body{
        background-color: #f0f3ff;
        padding: 15vh 15vh 15vh 15vh;
    }

    .process-section2-img-place{
        background: url(/src/assets/agile.png) no-repeat;
         height: 50vh;
        width: 100%;
        background-size:cover;
        background-position: center center;
    }

    .process-section3-img-place{
        background: url(/src/assets/details.png) no-repeat;
        min-height: 60vh;
        width: 100%;
        background-size:cover;
        background-position: center center;
    }

    .process-section2-content{
        display: flex;
        flex-direction: column;
    }

    .process-section2-title{
        font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    }

    .process-section2-text{
        font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6E6E6E;
    text-align: justify;
    margin-top: 2vh;
    margin-bottom: 2vh;
    }

    .gray{
        color: #6E6E6E;
    }


    .servicesdetails-section2-body{
        padding: 10vh 15vh 10vh 15vh;
    }

    .tab-value1{
        width: 90%;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #6E6E6E;
    }
    .value-icon1{
        color: #3C6AEA;;
        font-size: 30px;
        display: block;}

    .bold{
        font-weight: 700;
    }

    .tab-value1-place{
        display: flex;
    }

}
