.portfolio-section1-body{
    padding: 10vh 15vh 10vh 15vh;
    width: 100%;
    background-color: #f9fbfe;
    text-align:center;
}

.portfolio-image-place{
    display: flex;
    justify-content: center;
    align-items: center;
}

.portfolio-img-top{
    max-width: 80%;
}

#grid-1 {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    grid-template-areas: "a a a";
}


.portfolio-posts{
    width: 90%;
    margin-bottom: 7vh;
    border: none;
    transition: all 0.8s;
    position: relative;
    text-decoration: none;
    text-align: left;
    box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
    -webkit-box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
    -moz-box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
}

.portfolio-post-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #303030;
}

.portfolio-post-text{
    font-weight: 400;
font-size: 14px;
line-height: 28px;
color: #ACACAC;
margin-top: 1vh;
}

.portfolio-texts-place{
    padding: 3vh 3vh 2vh 3vh;
}

.portfolio-posts:hover{
    scale: 1.1;
}

.client-logo-card-place{
    position: absolute;
    top: 2vh;
    left: 2vh;

}

.client-logo-img{
    width: 20%;
}



@media(max-width: 576px){
    .portfolio-section1-body{
    padding: 10vh 5vh 10vh 5vh;
    width: 100%;
    background-color: #f9fbfe;
    }
    #grid-1 {
        grid-template-areas: "a";
        margin-left: 5vh;
    }


}

@media(min-width: 576px){
    .portfolio-section1-body{
        padding: 10vh 5vh 10vh 5vh;
        width: 100%;
        background-color: #f9fbfe;
    }
    #grid-1 {
        grid-template-areas: "a";
        margin-left: 5vh;
    }

}

@media(min-width: 768px){
    #grid-1 {
        grid-template-areas: "a a";
        margin-left: 3vh;
    }
    .portfolio-posts{
        margin-bottom: 4vh;
    }


}

@media(min-width:992px){
    .portfolio-section1-body{
        padding: 10vh 5vh 0 5vh;
    }


}

@media(min-width:1200px) {
    .portfolio-section1-body{
        padding: 10vh 15vh 10vh 15vh;
        width: 100%;
        background-color: #f9fbfe;
    }

    .portfolio-image-place{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .portfolio-img-top{
        max-width: 80%;
    }

    .portfolio-posts{
        width: 90%;
        margin-bottom: 7vh;
        border: none;
        transition: all 0.8s;
        text-decoration: none;
        box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
        -webkit-box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
        -moz-box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
    }

    .portfolio-post-title{
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #303030;
    }

    .portfolio-post-text{
        font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #ACACAC;
    margin-top: 1vh;
    }

    .portfolio-texts-place{
        padding: 3vh 3vh 2vh 3vh;
    }

    .portfolio-posts:hover{
        scale: 1.1;
    }
    #grid-1 {
        display: grid;
        grid-auto-rows: auto;
        grid-auto-columns: auto;
        grid-template-areas: "a a a";
    }


}
