.career-hero{
    background-color: #f9fbfe;
    padding: 25vh 15vh 15vh 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.career-hero-sub{
    font-weight: 600;
font-size: 18px;
line-height: 32px;
color: #3C6AEA;
}

.career-hero-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
width: 70%;
text-align: center;
color: #303030;
}

.career-hero-exp{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6E6E6E;
    width: 35%;
    text-align: center;
}

@media(max-width: 576px){
    .career-hero{
        padding: 15vh 5vh 15vh 5vh;
    }
    .career-hero-exp{
        width: 100%;
    }
    .career-hero-title{
    width: 100%;
    }


}

@media(min-width: 576px){
    .career-hero{
        padding: 15vh 5vh 15vh 5vh;
    }
    .career-hero-exp{
        width: 100%;
    }
        .career-hero-title{
    width: 100%;
    }

}

@media(min-width: 768px){


}

@media(min-width:992px){
    .career-hero{
        padding: 15vh 15vh 15vh 15vh;
    }
    .career-hero-exp{
        width: 100%;
    }


}

@media(min-width:1200px) {
    .career-hero{
        background-color: #f9fbfe;
        padding: 25vh 15vh 15vh 15vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .career-hero-sub{
        font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #3C6AEA;
    }

    .career-hero-title{
        font-weight: 600;
    font-size: 30px;
    width: 70%;
    line-height: 50px;
    color: #303030;
    }
    .career-hero-exp{
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #6E6E6E;
        width: 35%;
        text-align: center;
    }


}
