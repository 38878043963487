.process-section1-body{
    height: auto;
    width: 100%;
    background-color: #f9fbfe;
    padding: 15vh 15vh 5vh 15vh;
    color: #303030;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.process-section1-sub{
    color: #3C6AEA;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
}

.process-section1-title{
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    width: 100%;
}

.process-section1-caption{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6E6E6E;
    width: 80%;
}

.process-section1-tabs{
    margin-top: 5svh;
    width: 100%;
}

.process-tab-place{
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 80%;
}

ul.process-tab {
    display: flex;
    color: #6e6e6e;
    background-color: #f9fbfe;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    transition: all 2s;
    justify-content: space-around;
    padding: 0;
}

ul.process-tab li {
    padding-bottom: 2vh;
    list-style: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    width: 19%;
}

.process-title-icon{
    font-size: 35px;
}

.process-section-nav{
    text-align: center;
}

.process-section-navtitle{
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    margin-top: 1vh;
}

.value-icon{
    color: #3C6AEA;
    font-size: 30px;
    display: inline-block;
    width: 4vh;
}
.tab-value{
    display: inline-block;
    overflow-wrap: break-word;
}

.display{
    display: flex;
    margin-bottom: 2vh;
}

ul.process-tab li:hover {
    border-bottom: 2px solid #3C6AEA;
    color: #3C6AEA;
    font-weight: 600;

}

ul.process-tab li.active {
    font-weight: 600;
    color: #3C6AEA;
    border-bottom: 2px solid #3C6AEA;
}

.process-tab-content{
    margin-top: 15vh;
    color: #6E6E6E;
    margin-bottom: 3vh;
    text-align: left;
    height: 60vh;
}

.process-tabcontent-title{
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    margin-top: 5vh;
    color: #303030;
    margin-bottom: 1vh;
}

.process-tabcontent-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 2vh;
}

.process-tab-icon{
    font-size: 40vh;
    color: #D8E1FB;
}

.process-tab-icon-place{
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items:center;
    height: auto;
}

.section-nav-side-body{
    padding: 18vh 15vh 18vh 5vh;
    text-align: left;
}

.section-nav-side{
    position: sticky;
    top: 10vh;
}

ul.section-sidenav{
    list-style: none;
    padding-left: 2vh;
}

ul.section-sidenav li{
    font-size: 5vh;
    margin-bottom: 10vh;
    border: 1px solid #acacac;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9vh;
    width: 9vh;
    border-radius: 50%;
    transition: all 0.8s;
}


ul.section-sidenav li:hover{
    color: #3C6AEA;
    border: 1px solid #3C6AEA;
}

ul.section-sidenav li.active{
    color: #3C6AEA;
    border: 2px solid #3C6AEA;
    scale: 1.1;
}

ul.section-sidenav li:not(.active){
    scale: 0.8;
}


.section-content-scroll-body{
    height: 100vh;
}



@media(max-width: 576px){
    .process-section1-body{
    padding: 10vh 5vh 10vh 5vh;
    text-align: left;
    }
    ul.process-tab {
    display: block;
    margin-before: 1em;
    padding: 0;
    }
    ul.process-tab li {
    padding-bottom: 2vh;
    margin-bottom: 3vh;
    list-style: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    width: 100%;
    }
    .process-tab-icon{
    font-size: 20vh;
    }
    .process-section1-caption{
        width: 100%;
    }

    .section-nav-side-body{
        padding: 18vh 5vh 10vh 0;
    }
    .section-nav-side{
            position: sticky;
            top: 10vh;
            left: 0;
    }
        ul.section-sidenav li{
            height: 8vh;
            width: 8vh;
    }
    .process-tab-content{
        margin-top: 15vh;
        color: #6E6E6E;
        margin-bottom: 3vh;
        text-align: left;
        height: 100vh;
    }
    .value-icon{
        color: #3C6AEA;
        font-size: 25px;
        display: inline-block;
        width: 20%;
    }
    .tab-value{
        display: inline-block;
        overflow-wrap: break-word;
        width: 80%;
    }
    
    .display{
        display: flex;
        margin-bottom: 2vh;
    }


}

@media(min-width: 576px){
    .process-section1-body{
    padding: 10vh 5vh 10vh 5vh;
    text-align: left;
    }
    .process-section1-caption{
        width: 100%;
    }
        ul.process-tab {
    display: block;
    padding: 0;
    }
    ul.process-tab li {
    padding-bottom: 2vh;
    list-style: none;
    cursor: pointer;
    margin-bottom: 3vh;
    border-bottom: 2px solid transparent;
    width: 100%;
    }
    .process-tab-icon{
    font-size: 30vh;
    }
    .section-nav-side{
        position: sticky;
    }

    ul.section-sidenav{
        margin-left: 1vh;
        padding: 0;
    }
    .process-tab-content{
        margin-top: 15vh;
        color: #6E6E6E;
        margin-bottom: 3vh;
        text-align: left;
        height: 100vh;
    }

    ul.section-sidenav li{
        height: 8vh;
        width: 8vh;
        font-size: 4vh;
    }
    .section-nav-side-body{
        padding: 18vh 5vh 18vh 0;
    }

}

@media(min-width: 768px){
        ul.process-tab {
    display: flex;
    }
    ul.process-tab li {
    padding-bottom: 2vh;
    list-style: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    width: 100%;
    }
    .process-tab-place{
    width: 100%;
    }
        .process-tab-icon{
    font-size: 25vh;
    }

    ul.section-sidenav{
        margin: 0;
        padding: 0;
    }

    ul.section-sidenav li{
        height: 5vh;
        width: 5vh;
        font-size: 2vh;
    }
    .section-nav-side-body{
        padding: 18vh 5vh 18vh 5vh;
    }
    .section-nav-side{
        position: sticky;
    }

    ul.section-sidenav{
        margin-left: 1vh;
        padding: 0;
    }
    .process-tab-content{
        margin-top: 15vh;
        color: #6E6E6E;
        margin-bottom: 3vh;
        text-align: left;
        height: 100vh;
    }

    ul.section-sidenav li{
        height: 7vh;
        width: 7vh;
        font-size: 3vh;
    }


}

@media(min-width:992px){

}

@media(min-width:1200px) {
    .process-section1-body{
        height: auto;
        width: 100%;
        background-color: #f9fbfe;
        padding: 15vh 15vh 5vh 15vh;
        color: #303030;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .process-section1-sub{
        color: #3C6AEA;
        font-weight: 600;
        font-size: 30px;
        line-height: 50px;
    }

    .process-section1-title{
        font-size: 30px;
        font-weight: 600;
        line-height: 50px;
        width: 100%;
    }

    .process-section1-caption{
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #6E6E6E;
        width: 90%;
    }

    .process-section1-tabs{
        margin-top: 5vh;
        width: 100%;
    }

    .process-tab-place{
        margin: auto;
        justify-content: center;
        align-items: center;
        width: 80%;
    }

    ul.process-tab {
        display: flex;
        color: #6e6e6e;
        background-color: #f9fbfe;
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
        transition: all 2s;
        justify-content: space-around;
    }

    ul.process-tab li {
        padding-bottom: 2vh;
        list-style: none;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        width: 19%;
    }

    .process-title-icon{
        font-size: 35px;
    }

    .process-section-nav{
        text-align: center;
    }

    .process-section-navtitle{
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        text-align: center;
        margin-top: 1vh;
    }

    .value-icon{
        color: #3C6AEA;
        font-size: 30px;
        display: inline-flex;}

    ul.process-tab li:hover {
        border-bottom: 2px solid #3C6AEA;
        color: #3C6AEA;
        font-weight: 600;

    }

    ul.process-tab li.active {
        font-weight: 600;
        color: #3C6AEA;
        border-bottom: 2px solid #3C6AEA;
    }

    .process-tab-content{
        margin-top: 3vh;
        color: #6E6E6E;
        margin-bottom: 3vh;
        text-align: left;
    }

    .process-tabcontent-title{
        font-weight: 600;
        font-size: 30px;
        line-height: 50px;
        margin-top: 5vh;
        color: #303030;
    }

    .process-tabcontent-text{
        font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    }

    .process-tab-icon{
        font-size: 40vh;
        color: #D8E1FB;
    }

    .process-tab-icon-place{
        margin: auto;
        text-align: center;
        justify-content: center;
        align-items:center;
        height: auto;
    }
    .section-nav-side-body{
        padding: 18vh 15vh 18vh 5vh;
        text-align: left;
    }
    
    .section-nav-side{
        position: sticky;
        top: 10vh;
    }
    
    ul.section-sidenav{
        list-style: none;
        padding-left: 2vh;
    }
    
    ul.section-sidenav li{
        font-size: 5vh;
        margin-bottom: 10vh;
        border: 1px solid #acacac;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 9vh;
        width: 9vh;
        border-radius: 50%;
        transition: all 0.8s;
    }
    
    
    ul.section-sidenav li:hover{
        color: #3C6AEA;
        border: 1px solid #3C6AEA;
    }
    
    ul.section-sidenav li.active{
        color: #3C6AEA;
        border: 2px solid #3C6AEA;
        scale: 1.1;
    }
    
    ul.section-sidenav li:not(.active){
        scale: 0.8;
    }
    
    
    .section-content-scroll-body{
        height: 100vh;
    }

} 
