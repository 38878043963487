.tab-cards-position{
    top: -14vh;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
}

.tab-cards-align{
    justify-content: center;
    display: flex;
}



.tab-card{
    position: relative;
    border: none;
    transition: all 0.5s;
    width: 80%;
}

.card-bottom{
    background-color: #3C6AEA;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
    padding: 2vh;
    opacity: 1;
    transition: all 0.5s;
}

.card-overlay-tab{
    opacity: 0;
    transition: all 0.5s;
}

.icon-card-bottom{
    width: 9%;
    margin-right: 2vh;
}

    .icon-card-bottom-2{
        width: 8%;
        margin-right: 2vh;
    }

.tab-card:hover .card-bottom{
    opacity: 0;
}

.tab-card:hover .card-image{
    filter: brightness(50%)blur(2px);

}

.tab-card:hover .card-overlay-tab{
    opacity: 1;
}

.card-image{
    width:100%;
}

.overlay-title{
    font-weight: 600;
font-size: 18px;
line-height: 32px;
color: #fff;
margin-top: 1vh;
}

.icon-card-overlay{
    filter: invert(55%) sepia(69%) saturate(6263%) hue-rotate(214deg) brightness(92%) contrast(99%);
    margin-right: 2vh;
}

.overlay-text{
    font-weight: 400;
font-size: 14px;
line-height: 28px;
color: #fff;
margin-top: 1vh;
margin-bottom: 1vh;
}

.overlay-button{
    width: 100%;
    background-color: #3C6AEA;
    padding: 2vh;

}

.card-img-overlay{
    padding: 0;
}

.text-placement-card{
    margin: 2vh;
}


@media(max-width: 576px){
    .tab-cards-position{
        position: relative;
        margin-top: 7vh;
    }
    .tab-card{
        margin-top: 5vh;
        width: 90%;
    }


}

@media(min-width: 576px){
    .tab-cards-position{
        position: relative;
        margin-top: 7vh;
    }
    .tab-card{
        margin-top: 3vh;
    }

}

@media(min-width: 768px){
        .tab-card{
        margin-top: 3vh;
        width: 100%;
        margin-left: 2vh;
        margin-right: 2vh;
    }



}

@media(min-width:992px){
    .tab-card{
        margin-top: 3vh;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .card-image{
        width: 100%;
    }


}

@media(min-width:1200px) {
    .tab-cards-position{
        top: -14vh;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        width: 100%;
    }

    .tab-cards-align{
        justify-content: center;
        display: flex;
    }



    .tab-card{
        position: relative;
        border: none;
        transition: all 0.5s;
        width: 90%;
    }

    .card-bottom{
        background-color: #3C6AEA;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: #fff;
        padding: 2vh;
        opacity: 1;
        transition: all 0.5s;
    }

    .card-overlay-tab{
        opacity: 0;
        transition: all 0.5s;
    }

    .icon-card-bottom{
        width: 8%;
        margin-right: 3vh;
    }

    .icon-card-bottom-2{
        width: 10%;
        margin-right: 3vh;
    }

    .tab-card:hover .card-bottom{
        opacity: 0;
    }

    .tab-card:hover .card-image{
        filter: brightness(50%)blur(2px);

    }

    .tab-card:hover .card-overlay-tab{
        opacity: 1;
    }

    .card-image{
        width:100%;
    }

    .overlay-title{
        font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
    margin-top: 1vh;
    }

    .icon-card-overlay{
        filter: invert(55%) sepia(69%) saturate(6263%) hue-rotate(214deg) brightness(92%) contrast(99%);
        margin-right: 2vh;
    }

    .overlay-text{
        font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #fff;
    margin-top: 1vh;
    margin-bottom: 1vh;
    }

    .overlay-button{
        width: 100%;
        background-color: #3C6AEA;
        padding: 2vh;
    }


}
