.home-section2-body{
    min-height: 70vh;
    background-color: #101010;
}

.home-section2-section{
    padding: 15vh 10vh 15vh 10vh;
    min-height: 70vh;
}

.home-section2-sub{
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #3C6AEA;
}

.home-section2-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: #fff;
    width: 50%;
}


.section2{
    display: flex;
    list-style: none;
    padding: 1vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
}

.section-nav{
    width: 33.3%;
    padding: 2vh;
    text-align: center;
    background-color: #101010;
    border-radius: 1vh;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    transition: all 0.8s;
    color: #fff;
}

.section-nav:hover{
    background-color: #3C6AEA;

}





.section2-content{
    color: #fff;
    margin-top: 3vh;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    text-align: justify;

}

.section2-img-place {
    background: linear-gradient(to bottom, rgba(60, 106, 234, 0.2), rgba(60, 106, 234, 0.9)), url(/src/assets/1.png) no-repeat;
    height: 50vh;
    width: 100%;
    background-size:cover;
    background-position: center center;
}

.section-row{
    min-height: 70vh;
}


@media(max-width: 576px){
    .home-section2-body{
        min-height: 80vh;
    }
    .home-section2-section{
        padding: 10vh 5vh 10vh 5vh;
        min-height: 90vh;
    }
    .section-row{
        min-height: 80vh;
    }
    .home-section2-title{
        width: 100%;
    }




}

@media(min-width: 576px){
    .home-section2-section{
        padding: 10vh 5vh 10vh 5vh;
        min-height: 60vh;
    }
    .home-section2-title{
        width: 80%;
    }
    .section2-img-place {
        width: 100%;
    }

}

@media(min-width: 768px){
    .home-section2-section{
        padding: 10vh 5vh 10vh 5vh;
        min-height: 50vh;
    }


}

@media(min-width:992px){
    .home-section2-section{
        padding: 10vh 5vh 10vh 5vh;
        min-height: 40vh;
    }


}

@media(min-width:1200px) {
    .home-section2-body{
        min-height: 70vh;
        background-color: #101010;
    }

    .home-section2-section{
        padding: 15vh 10vh 15vh 5vh;
        min-height: 80vh;
    }

    .home-section2-sub{
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        color: #3C6AEA;
    }

    .home-section2-title{
        font-weight: 600;
        font-size: 27px;
        line-height: 40px;
        color: #fff;
        width: 50%;
    }

    .section-row{
        min-height: 75vh;
    }





    .section2-content{
        color: #fff;
        margin-top: 3vh;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        text-align: justify;

    }

    .section2-img-place {
        background: linear-gradient(to bottom, rgba(60, 106, 234, 0.2), rgba(60, 106, 234, 0.9)), url(/src/assets/1.png) no-repeat;
        height: 100%;
        width: 100%;
        background-size:cover;
        background-position: center center;
    }



}
