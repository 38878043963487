.header-background{
    padding: 3vh 15vh 3vh 15vh;
    background-color: #f9fbfe;
    position: static;
}

.header-placement .nav-link{
    margin-left: 2vh;
    color: #101010;
}

.logo-navigation-place{
    width: 10%;
    object-contain: fill;
}


.logo-navigation{
    max-width: 100%;
}

.burger-header-color{
    background-color: #101010 !important;
    color: #101010 !important;
    opacity: 1;
}

.navbar-toggler{
    border: none;
}

@media(max-width: 576px){
    .header-background{
        padding: 2vh 5vh 2vh 5vh;
    }
    .logo-navigation-place{
        width: 30%;
        object-contain: fill;
    }
}

@media(min-width: 576px){
    .header-background{
        padding: 2vh 5vh 2vh 5vh;
    }
    .logo-navigation-place{
        width: 25%;
        object-contain: fill;
    }

}

@media(min-width: 768px){
    .logo-navigation-place{
        width: 20%;
        object-contain: fill;
    }


}

@media(min-width:992px){
    .logo-navigation-place{
        width: 15%;
        object-contain: fill;
    }


}

@media(min-width:1200px) {
    .header-background{
        padding: 3vh 15vh 3vh 15vh;
        background-color: #f9fbfe;
        position: static;
    }

    .header-placement .nav-link{
        margin-left: 2vh;
        color: #101010;
    }

    .logo-navigation-place{
        width: 10%;
        object-contain: fill;
    }


    .logo-navigation{
        max-width: 100%;
    }

    .burger-header-color{
        background-color: #101010 !important;
        color: #101010 !important;
        opacity: 1;
    }

    .navbar-toggler{
        border: none;
    }



}
