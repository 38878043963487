.services-hero{
    padding: 25vh 15vh 25vh 15vh;
    background:linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_1.png) no-repeat center fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.services-hero-title{

    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: #fff;
    width: 20%;
}

@media(max-width: 576px){
    .services-hero{
    padding: 25vh 5vh 25vh 5vh;
    background-color: #101010;
    }
    .services-hero-title{
        width: 40%;
    }


}

@media(min-width: 576px){
    .services-hero{
    padding: 25vh 5vh 25vh 5vh;
    background-color: #101010;
    }
    .services-hero-title{
        width: 40%;
    }

}

@media(min-width: 768px){
    .services-hero{
    padding: 25vh 5vh 25vh 5vh;
    background-color: #101010;
    }
    .services-hero-title{
        width: 30%;
    }


}

@media(min-width:992px){
    .services-hero{
    padding: 25vh 5vh 25vh 5vh;
    background-color: #101010;
    }
    .services-hero-title{
        width: 20%;
    }


}

@media(min-width:1200px) {
    .services-hero{
    padding: 25vh 15vh 25vh 15vh;
    background-color: #101010;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

}

.services-hero-title{

    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: #fff;
    width: 20%;
}


}
