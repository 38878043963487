.clientblack-body{
    background-color: #101010;
    padding: 18vh 15vh 18vh 15vh;
    text-align: center;
}

.clientblack-sub{
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #3C6AEA;
}

.clientblack-title{
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #3C6AEA;
}

.clientblack-image{
    height: 6vh;
    margin-top: 4vh;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(64deg) brightness(107%) contrast(103%);
}

.clientblack-image:hover{
    scale: 1.1;
    filter: none;
}

#grid-clientblack {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    grid-template-areas: "a a a a";
}

@media(max-width: 576px){
    .clientblack-body{
        padding: 18vh 5vh 18vh 5vh;
    }
    #grid-clientblack {
        grid-template-areas: "a";
    }
    .clientblack-image{
        margin-top: 7vh;
    }


}

@media(min-width: 576px){
    .clientblack-body{
        padding: 15vh 5vh 15vh 5vh;
    }
    #grid-clientblack {
        grid-template-areas: "a";
    }
    .clientblack-image{
        margin-top: 7vh;
    }

}

@media(min-width: 768px){
    .clientblack-body{
        padding: 15vh 5vh 15vh 5vh;
    }
    #grid-clientblack {
        grid-template-areas: "a a";
    }
    .clientblack-image{
        margin-top: 7vh;
        height: 4vh;
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .clientblack-body{
        background-color: #101010;
        padding: 18vh 15vh 18vh 15vh;
        text-align: center;
    }

    .clientblack-sub{
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: #3C6AEA;
    }

    .clientblack-title{
        font-weight: 600;
        font-size: 30px;
        line-height: 50px;
        color: #3C6AEA;
    }

    .clientblack-image{
        height: 6vh;
        margin-top: 4vh;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(64deg) brightness(107%) contrast(103%);
    }


    #grid-clientblack {
        display: grid;
        grid-auto-rows: auto;
        grid-auto-columns: auto;
        grid-template-areas: "a a a a";
    }


}
