.footer-body{
    width: 100%;
    background-color: #101010;
    padding: 10vh 15vh 7vh 15vh;
    color: #fff;
}

.footer-background{
    background-color: #101010;
}

.footer-exp{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    width: 50%;
    margin-top: 1vh;
}

.footer-quick-link{
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;color: #3C6AEA;
    margin-bottom: 3vh;
}


.footer-link-decoration{
    text-decoration: none;
    display: block;
    color: #FFFFFF;
}

.contact-info{
    font-weight: 400;
font-size: 14px;
line-height: 28px;
}

hr.footer{
    border-top: 2px solid #fff;
    opacity: 1;
}

.footer-bottom{
    color: #1c1c1c;
    margin: auto;
    align-items: center;
    display: flex;
    padding: 3vh 15vh 3vh 15vh;
    background-color: #101010;
    justify-content: space-between;

}

.footer-bottom-title{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #fff;
    align-items: flex-start;
}

.footer-bottom-link{
    font-weight: 400;
font-size: 14px;
line-height: 28px;
color: #FFFFFF;
}

.footer-bottom-decoration{
    text-decoration: none;
    color: #FFFFFF;
    margin-left: 3vh;
    margin-right: 3vh;
}

.footer-logo-place{
    width: 30%;
}

.footer-logo{
    max-width: 100%;
}


@media(max-width: 576px){
    .footer-body{
        padding: 10vh 5vh 7vh 5vh;
    }
    .footer-logo-place{
        width: 50%;
    }
    .footer-exp{
        width: 70%;
    }
    .footer-quick-link{
        margin-top: 3vh;
        margin-bottom: 0;
    }
    .footer-bottom{
        padding: 3vh 5vh 3vh 5vh;
        background-color: #101010;
    }


}

@media(min-width: 576px){
        .footer-body{
        padding: 10vh 5vh 7vh 5vh;
    }
    .footer-logo-place{
        width: 50%;
    }
    .footer-exp{
        width: 80%;
    }
    .footer-quick-link{
        margin-top: 3vh;
        margin-bottom: 0;
    }
    .footer-bottom{
        padding: 3vh 5vh 3vh 5vh;
        background-color: #101010;
    }

}

@media(min-width: 768px){
            .footer-body{
        padding: 10vh 5vh 7vh 5vh;
    }
    .footer-logo-place{
        width: 50%;
    }
    .footer-exp{
        width: 80%;
    }
    .footer-quick-link{
        margin-top: 2vh;
        margin-bottom: 0;
    }
    .footer-bottom{
        padding: 3vh 5vh 3vh 5vh;
        background-color: #101010;
    }


}

@media(min-width:992px){
    .footer-quick-link{
        margin-top: 2vh;
        margin-bottom: 1vh;
    }


}

@media(min-width:1200px) {
    .footer-body{
    width: 100%;
    background-color: #101010;
    padding: 10vh 15vh 7vh 15vh;
    color: #fff;
}

.footer-background{
    background-color: #101010;
}

.footer-exp{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    width: 50%;
    margin-top: -0.5vh;
}

.footer-quick-link{
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;color: #3C6AEA;
    margin-bottom: 3vh;
}


.footer-link-decoration{
    text-decoration: none;
    display: block;
    color: #FFFFFF;
}

.contact-info{
    font-weight: 400;
font-size: 14px;
line-height: 28px;
}

hr.footer{
    border-top: 2px solid #fff;
    opacity: 1;
}

.footer-bottom{
    color: #1c1c1c;
    margin: auto;
    align-items: center;
    display: flex;
    padding: 3vh 15vh 3vh 15vh;
    background-color: #101010;
    justify-content: space-between;

}

.footer-bottom-title{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #fff;
    align-items: flex-start;
}

.footer-bottom-link{
    font-weight: 400;
font-size: 14px;
line-height: 28px;
color: #FFFFFF;
}

.footer-bottom-decoration{
    text-decoration: none;
    color: #FFFFFF;
    margin-left: 3vh;
    margin-right: 3vh;
}

.footer-logo-place{
    width: 30%;
}

.footer-logo{
    max-width: 100%;
}


}
