.clientlight-body{
    padding: 18vh 15vh 18vh 15vh;
    background-color: #f9fbff;
    text-align: center;
}

.clientlight-title{
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #303030;
}

.clientlight-image{
    height: 6vh;
    margin-top: 5vh;
    filter: invert(16%) sepia(28%) saturate(7%) hue-rotate(327deg) brightness(91%) contrast(91%);
}

.clientlight-image:hover{
    scale: 1.1;
    filter: none;
}

@media(max-width: 576px){
    .clientlight-body{
        padding: 18vh 5vh 18vh 5vh;
    }


}

@media(min-width: 576px){
    .clientlight-body{
        padding: 18vh 5vh 18vh 5vh;
    }

}

@media(min-width: 768px){
    .clientlight-body{
        padding: 18vh 5vh 18vh 5vh;
    }
    .clientlight-image{
        height: 4vh;
        margin-top: 5vh;
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .clientlight-body{
        padding: 18vh 15vh 18vh 15vh;
        background-color: #f9fbff;
        text-align: center;
    }

    .clientlight-title{
        font-weight: 600;
        font-size: 30px;
        line-height: 50px;
        color: #303030;
    }

    .clientlight-image{
        height: 6vh;
        margin-top: 5vh;
        filter: invert(16%) sepia(28%) saturate(7%) hue-rotate(327deg) brightness(91%) contrast(91%);
    }


}
