.career-card-body{
    padding: 18vh 15vh 18vh 15vh;
    background-color: #f9fbfe;
    text-align: center;
}

.cards-career{
    margin-bottom: 6vh;
    border-radius:0.6vw;
    padding: 6vh 5vh 6vh 5vh ;
    background-color:#ffffff;
    border: 1px #f9fbfe;
    text-align:left;
    width: 95%;
    height: auto;
    transition: all 0.8s;
    box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
    -webkit-box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
    -moz-box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
}

.cards-career:hover{
    border: 1px solid #3C6AEA;
    scale: 1.1;
    background-color: #3C6AEA;;
}

.cards-career:hover .card-career-title{
    color: #fff;
}

.cards-career:hover .card-career-exp{
    color: #fff;
}

.cards-career:hover .cards-career-level{
    color: #fff;
}

.card-career-title{
    font-weight: 600;
    display: inline-block;
    font-size: 20px;
    line-height: 32px;
    color: #303030;
}

.cards-career-level{
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #FFFFFF;
    border-radius: 50%;
    height: 5vh;
    width: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-career-exp{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #ACACAC;
    height: 40vh;
    overflow-y:scroll;
    overflow-x:hidden;
}

.career-button{
    background-color: #ffffff !important;
    border: none;
    border-radius: 1vw;
    color:#4070ff;
    width: 100%;
    padding: 1vh 0 1vh 0 ;
    margin-top: 1vw;
}

.career-button:hover{
    color: #ffffff;
    background-color: #4070ff !important;
}

hr.career-line{
    border-top: 2px solid #f0f3fd;
}

.section-btn-load-more{
    background-color: #3C6AEA;
    padding: 2vh 4vh 2vh 4vh !important;
}


@media(max-width: 576px){
    .career-card-body{
        padding: 10vh 5vh 14vh 5vh;
        background-color: #f9fbfe;
    }


}

@media(min-width: 576px){
    .career-card-body{
    padding: 10vh 5vh 14vh 5vh;
    background-color: #f9fbfe;
}

}

@media(min-width: 768px){


}

@media(min-width:992px){


}

@media(min-width:1200px) {


}
