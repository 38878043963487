.home-section1-body{
    height: auto;
    width: 100%;
    padding: 20vh 15vh 20vh 20vh;
    background-color: #101010;
    color: #fff;
    position: relative;
}

.home-section1-sub{
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #3C6AEA;
}

.home-section1-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    margin-top: 2vh;
    width: 60%;
}

hr.home-section1-line{
    border-top: 3px solid #3C6AEA;
    opacity: 1;
}

.home-section1-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-align: justify;
}

.home-section1-bodybottom{
    min-height: 60vh;
    background-color: #fff;
    width: 100%;
    position: relative;
}


@media(max-width: 576px){
    .home-section1-body{
    padding: 10vh 5vh 10vh 5vh;
}


}

@media(min-width: 576px){
        .home-section1-body{
    padding: 10vh 5vh 10vh 5vh;
    }
    .home-section1-title{
    width: 100%;
}

}

@media(min-width: 768px){


}

@media(min-width:992px){
    .home-section1-bodybottom{
        min-height: 5vh;
    }


}

@media(min-width:1200px) {
    .home-section1-body{
    height: auto;
    width: 100%;
    padding: 20vh 15vh 20vh 20vh;
    background-color: #101010;
    color: #fff;
    position: relative;
}

.home-section1-sub{
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #3C6AEA;
}

.home-section1-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    margin-top: 2vh;
    width: 60%;
}


.home-section1-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-align: justify;
    margin-top: 3vh;
}

.home-section1-bodybottom{
    min-height: 60vh;
    padding: 20vh 15vh 15vh 20vh;
    background-color: #fff;
    width: 100%;
    position: relative;
}


}
