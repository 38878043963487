.about-hero{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_7.png);
    height: 70vh;
    width: 100%;
    padding: 25vh 20vh 25vh 20vh;
    background-size: cover;
    text-align: center;
}

.about-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: #fff;
    width: 20%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
}


@media(max-width: 576px){
    .about-hero{
        height: auto;
        width: 100%;
        padding: 30vh 5vh 30vh 5vh;
    }
    .about-title{
        width: 60%;
    }


}

@media(min-width: 576px){
    .about-hero{
        height: auto;
        width: 100%;
        padding: 30vh 5vh 30vh 5vh;
    }
    .about-title{
        width: 40%;
    }

}

@media(min-width: 768px){
    .about-hero{
        height: auto;
        width: 100%;
        padding: 25vh 5vh 25vh 5vh;
    }
    .about-title{
        width: 40%;
    }


}

@media(min-width:992px){
    .about-hero{
        height: auto;
        width: 100%;
        padding: 25vh 5vh 25vh 5vh;
    }
    .about-title{
        width: 25%;
    }


}

@media(min-width:1200px) {
    .about-hero{
        background:linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_7.png)center;
    height: auto;
    background-size: cover;
    width: 100%;
    padding: 30vh 20vh 25vh 20vh;
    text-align: center;
}

.about-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: #fff;
    width: 20%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
}


}
