.about-section3-body{
    height: auto;
    width: 100%;
    padding: 20vh 15vh 20vh 20vh;
    background-color: #3C6AEA;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


.about-section3-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 50px;
    margin-top: 2vh;
    width: 90%;
    text-align: center;
}



.about-section3-bodybottom{
    height: auto;
    padding: 15vh 15vh 10vh 20vh;
    background-color: #fff;
    width: 100%;
    position: relative;
}

.about-section3-overlay{
    width: 80%;
    height: 12vh;
    background-color: #fff;
    position: absolute;
    margin: auto;
    top: -7vh;
    left: 0;
    right: 0;
    border-radius: 1vh;
    display: flex;
    align-items: center;
    padding: 0 5vh 0 5vh;
    justify-content: space-between;
    box-shadow: 4px 4px 10px 5px rgba(63, 86, 238, 0.05);
}

.about-section3-overlay-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #3C6AEA;
}

.about-section3-button{
    font-weight: 600;
font-size: 14px;
line-height: 28px;
background: #101010;
border: 1px solid #101010;
padding: 1.5vh 3vh 1.5vh 3vh;
}

@media(max-width: 576px){
    .about-section3-body{
        padding: 10vh 4vh 12vh 4vh;
    }
    .about-section3-title{
        width: 100%;
        text-align: left;
    }
    .about-section3-overlay{
        width: 100%;
        height: auto;
        border-radius: 1vh;
        padding: 2vh 5vh 2vh 5vh;
        display: block;
    }




}

@media(min-width: 576px){
    .about-section3-body{
        padding: 10vh 4vh 12vh 4vh;
    }
    .about-section3-title{
        width: 80%;
        text-align: left;
    }
    .about-section3-overlay{
        width: 100%;
        height: 12vh;
        border-radius: 1vh;
        padding: 0 5vh 0 5vh;
    }

}

@media(min-width: 768px){
    .about-section3-body{
        padding: 5vh 4vh 12vh 4vh;
    }
    .about-section3-bodybottom{
        height: auto;
        padding: 10vh 15vh 10vh 20vh;
        background-color: #fff;
        width: 100%;
        position: relative;
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .about-section3-body{
        height: auto;
        width: 100%;
        padding: 15vh 15vh 18vh 15vh;
        background-color: #3C6AEA;
        color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .about-section3-title{
        font-weight: 600;
        font-size: 27px;
        line-height: 50px;
        margin-top: 2vh;
        width: 90%;
        text-align: center;
    }



    .about-section3-bodybottom{
        height: auto;
        padding: 15vh 15vh 10vh 20vh;
        background-color: #fff;
        width: 100%;
        position: relative;
    }

    .about-section3-overlay{
        width: 80%;
        height: 12vh;
        background-color: #fff;
        position: absolute;
        margin: auto;
        top: -7vh;
        left: 0;
        right: 0;
        border-radius: 1vh;
        display: flex;
        align-items: center;
        padding: 0 5vh 0 5vh;
        justify-content: space-between;
        box-shadow: 4px 4px 10px 5px rgba(63, 86, 238, 0.05);
    }

    .about-section3-overlay-title{
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #3C6AEA;
    }

    .about-section3-button{
        font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    background: #101010;
    border: 1px solid #101010;
    padding: 1.5vh 3vh 1.5vh 3vh;
    }


}
