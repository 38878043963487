.process-hero{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_3.png)center;
    height: auto;
    width: 100%;
    background-size: cover;
    padding: 25vh 20vh 25vh 20vh;
    text-align: center;
}

.process-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: #fff;
    width: 20%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
}

@media(max-width: 576px){
    .process-hero{
    padding: 25vh 5vh 25vh 5vh;
    }
    .process-title{
        width: 60%;
    }

}

@media(min-width: 576px){
    .process-hero{
    padding: 25vh 5vh 25vh 5vh;
    }
    .process-title{
        width: 30%;
    }

}

@media(min-width: 768px){


}

@media(min-width:992px){
    .process-hero{
    padding: 25vh 5vh 25vh 5vh;
    }
    .process-title{
        width: 20%;
    }


}

@media(min-width:1200px) {
    .process-hero{
        background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_3.png)center;
        background-size: cover;
    height: auto;
    width: 100%;
    padding: 25vh 20vh 25vh 20vh;
    text-align: center;
}

.process-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: #fff;
    width: 20%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
}


}
