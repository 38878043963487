.about-section1-body{
    width: 100%;
    height: auto;
    background-color: #f0f3ff;
    padding: 20vh 18vh 20vh 20vh;
}

.about-image-place{
    width: 100%;
    object-contain: fill;
    display: flex;
    justify-content: center;
}

.about-image{
    max-width: 100%;
}

.about-section1-section{
    justify-content: center;
    display: flex;
   flex-direction: column;
    height: 100%;
}

.about-section1-sub{

    font-weight: 600;
font-size: 18px;
line-height: 32px;
color: #3C6AEA;
}

.about-section1-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
}

.about-section1-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
text-align: justify;
color: #6E6E6E;
}


@media(max-width: 576px){
    .about-section1-body{
        height: auto;
        padding: 15vh 5vh 20vh 5vh;
    }
    .about-section1-section{
        padding-top: 3vh;
    }


}

@media(min-width: 576px){
        .about-section1-body{
        height: auto;
        padding: 10vh 5vh 15vh 5vh;
    }
    .about-section1-section{
        padding-top: 3vh;
    }

}

@media(min-width: 768px){
    .about-section1-body{
        height: auto;
        padding: 10vh 5vh 10vh 5vh;
    }
    .about-section1-section{
        padding-top: 0;
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .about-section1-body{
    width: 100%;
    height: auto;
    background-color: #f0f3ff;
    padding: 10vh 18vh 10vh 20vh;
}

.about-image-place{
    width: 100%;
    object-contain: fill;
    display: flex;
    justify-content: center;
}

.about-image{
    max-width: 100%;
}

.about-section1-section{
    justify-content: center;
    display: flex;
   flex-direction: column;
   margin-left: 3vh;
    height: 100%;
}

.about-section1-sub{

    font-weight: 600;
font-size: 18px;
line-height: 32px;
color: #3C6AEA;
}

.about-section1-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
}

.about-section1-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
text-align: justify;
color: #6E6E6E;
}


}
