.about-section2-body{
    background-color: #f0f3ff;
    width: 100%;
    height: auto;
    padding: 18vh 20vh 18vh 20vh;
}

.about-section2-cards{
    background-color: #fff;
    text-align: center;
    padding: 6vh 3vh 6vh 5vh;
    border: none;
    text-decoration: none;
    color: #1E1E1E;
    box-shadow: 10px 10px 18px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 10px 10px 18px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 10px 10px 18px 0px rgba(0,0,0,0.1);
    transition: all 0.8s;
}

.about-cards-icon{
    filter: invert(55%) sepia(69%) saturate(6263%) hue-rotate(214deg) brightness(92%) contrast(99%);
}

.about-cards-title{
    margin-top: 2vh;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #3C6AEA;
}

.about-cards-text{
    margin-top: 3vh;
    font-weight: 400;
font-size: 13px;
line-height: 24px;
}

.about-section2-cards:hover{
    background-color: #3C6AEA;
    scale: 1.1;
}

.about-section2-cards:hover .about-cards-title{
    color: #fff;
}

.about-section2-cards:hover .about-cards-text{
    color: #fff;
}

.about-section2-cards:hover .about-cards-icon{
    filter: invert(0%) sepia(100%) saturate(8%) hue-rotate(206deg) brightness(96%) contrast(104%);
}


@media(max-width: 576px){
    .about-section2-body{
        padding: 10vh 5vh 15vh 5vh;
    }
    .about-section2-cards{
        margin-top: 3vh;
        padding: 3vh 4vh 3vh 4vh;
        transition: all 0.8s;
    }


}

@media(min-width: 576px){
    .about-section2-body{
        padding: 10vh 5vh 15vh 5vh;
    }
    .about-section2-cards{
        margin-top: 3vh;
        padding: 3vh 4vh 3vh 4vh;
        transition: all 0.8s;
    }

}

@media(min-width: 768px){
    .about-section2-body{
        padding: 8vh 5vh 10vh 5vh;
    }
    .about-section2-cards{
        margin-top: 3vh;
        padding: 3vh 4vh 3vh 4vh;
        transition: all 0.8s;
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .about-section2-body{
        background-color: #f0f3ff;
        width: 100%;
        height: auto;
        padding: 18vh 20vh 18vh 20vh;
    }

    .about-section2-cards{
        background-color: #fff;
        text-align: center;
        padding: 6vh 3vh 6vh 2vh;
        border: none;
        height: 40vh;
        box-shadow: 10px 10px 18px 0px rgba(0,0,0,0.1);
        -webkit-box-shadow: 10px 10px 18px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 10px 10px 18px 0px rgba(0,0,0,0.1);
        transition: all 0.8s;
    }

    .about-cards-icon{
        filter: invert(55%) sepia(69%) saturate(6263%) hue-rotate(214deg) brightness(92%) contrast(99%);
        width: 10%;
    }

    .about-cards-title{
        margin-top: 2vh;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        color: #3C6AEA;
    }

    .about-cards-text{
        margin-top: 3vh;
        font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    }


}
