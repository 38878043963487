.home-section3-body{
    width: 100%;
    background-color: #f9fbfe;
    padding: 10vh 15vh 10vh 15vh;
    color: #303030;
    height: auto;
}

.home-section3-sub{
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #3C6AEA;
}

.home-section3-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    width: 60%;
}

.home-section3-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    width: 100%;
}

.home-section3-section{
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
}

.home-section3-number{
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #3C6AEA;
}

.margin-top-section3{
    margin-top: 2vh;
}

.img-margin-top{
    margin-top: 3vh;
}

.client-img{
    transition: all 0.8s;
}

.client-img:hover{
    scale: 1.1;
}


@media(max-width: 576px){
    .home-section3-body{
    padding: 10vh 5vh 10vh 5vh;
    }


}

@media(min-width: 576px){
        .home-section3-body{
    padding: 10vh 5vh 10vh 5vh;
    }
    .home-section3-title{
    width: 40%;
}

}

@media(min-width: 768px){
    .home-section3-body{
    padding: 10vh 5vh 10vh 5vh;
    }
    .home-section3-title{
    width: 30%;
}


}

@media(min-width:992px){
    .home-section3-title{
    width: 70%;
    }


}

@media(min-width:1200px) {
    .home-section3-body{
    width: 100%;
    background-color: #f9fbfe;
    padding: 10vh 15vh 10vh 15vh;
    color: #303030;
    height: auto;
}

.home-section3-sub{
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #3C6AEA;
}

.home-section3-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    width: 60%;
}

.home-section3-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    width: 100%;
}

.home-section3-section{
    text-align: center;
font-weight: 600;
font-size: 16px;
line-height: 30px;
}

.home-section3-number{
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #3C6AEA;
}

.margin-top-section3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


}
