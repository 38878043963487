.blog-hero{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_9.png) no-repeat center fixed;
    color: #fff;
    height: 70vh;
    width: 100%;
    padding: 25vh 20vh 25vh 20vh;
    background-size: cover;
    text-align: center;
}

.blog-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: #fff;
    width: 20%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
}

@media(max-width: 576px){
    .blog-hero{
        height: auto;
        padding: 25vh 5vh 25vh 5vh;
    }


}

@media(min-width: 576px){
    .blog-hero{
        height: auto;
        padding: 25vh 5vh 25vh 5vh;
    }

}

@media(min-width: 768px){


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .blog-hero{
        background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_9.png);
        color: #fff;
        height: 70vh;
        width: 100%;
        padding: 25vh 20vh 25vh 20vh;
        background-size: cover;
        text-align: center;
    }

    .blog-title{
        font-weight: 600;
        font-size: 27px;
        line-height: 40px;
        color: #fff;
        width: 20%;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
    }


}
