.portfolio-details-hero{
    padding: 25vh 15vh 20vh 15vh;
    text-align: center;
    background-color: #f0f3ff;
}

.portfolio-details-link{
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #3C6AEA;
}

.link-title{
    font-weight: 400;
font-size: 18px;
line-height: 32px;
color: #acacac;
}

.details-image-place{
    width: 90%;
    object-fit: cover;
    margin-top: 5vh;
    margin-left: 5vh;
}

.details-image{
    max-width: 100%;
}

.portfoliodetails-title{
    margin-top: 3vh;
    font-weight: 600;
font-size: 30px;
line-height: 50px;
color: #303030;
}

.portfoliodetails-section1{
    background-color: #f9fbfe;
    padding: 10vh 15vh 10vh 15vh;
}

.portodetails-section1-body{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
color: #303030;
}

.portodetails-section1-industry{
    font-weight: 600;
font-size: 16px;
line-height: 30px;
color: #3C6AEA;
}

.portodetails-section1-text{
    font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #6E6E6E;
margin-top: 3vh;
margin-bottom: 3vh;
}

.deliv{
    font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #6E6E6E;
margin-top: 2vh;
}

.portfoliodetails-section2{
    padding: 10vh 15vh 10vh 15vh;
    background-color: #101010;

}

.portodetails-section2-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
color: #fff;
margin-bottom: 3vh;
}

.circle{
    border: 10px solid #3C6AEA;
    background-color: #101010;
    border-radius: 50%;
    height: 20vh;
    width: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
font-size: 50px;
line-height: 75px;
color: #fff;
}

.project-summary-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
color: #fff;
}

.project-summary-text{
    font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #fff;
margin-top: 3vh;
}

.details-year{
    font-weight: 600;
font-size: 16px;
line-height: 30px;
color: #fff;
margin-top: 2vh;
}

.details-platform{
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    margin-top: 2vh;

}
.platform-image{
    width: 5%;
}

.portfoliodetails-section3{
    padding: 10vh 15vh 10vh 15vh;
    background-color: #f0feff;
    text-align: center;
}

.portdetails-section3-sub{
    font-weight: 600;
font-size: 18px;
line-height: 32px;
color: #4070FF;
}

.portdetails-section3-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
color: #303030;
margin-bottom: 4vh;
}

.scope-place{
    display: flex;
    justify-content: center;
    align-items: center;
}

.scope-circle{
    background-color: #acacac;
    border-radius: 50%;
    height: 15vh;
    width: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.platform-place{
    display: flex;
}

    .client-logo-place{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40vh;
    }
    .client-logo{
        width: 80%;
}


.control-button-place{
    background-color: #f9fbfe;
    height: auto;
    display: flex;
}


.control-button-next{
    padding: 3vh 0 3vh 0 ;
    display: inline-block;
    width: 50%;

}

.control-button-next:hover{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/11.png) no-repeat center fixed;
    background-size: cover;
}

.control-button-prev{
    padding: 3vh 0 3vh 0 ;
    display: inline-block;
    width: 50%;
    transition: all 0.8s;
}

.control-button-prev:hover{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_9.png) no-repeat center fixed;
    background-size: cover;
    transition: all 0.8s;
}

.control-button-prev:hover .control-button-title,
.control-button-prev:hover .client-control-name,
.control-button-prev:hover .arrow-color{
    color: #fff;
}

.control-button-next:hover .control-button-title,
.control-button-next:hover .client-control-name,
.control-button-next:hover .arrow-color{
    color: #fff;
}

.control-button-title{
    font-size: 24px;
    line-height: 50px;
    color: #ACACAC;
    font-weight: 600;
    margin-left: 4vh;
    transition: all 0.8s;
}

.client-control-name{
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    color: #303030;
    margin-left: 4vh;
    transition: all 0.8s;
}

.button-placement-control-left{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 13vh;
    transition: all 0.8s;
}

.button-placement-control-right{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 13vh;
    transition: all 0.8s;
}

.arrow-color{
    color: #303030;
    font-size: 5vh;
}

.button-margin{
    margin-left: 10vh;
}

.banner-tech-stack{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_2.png) no-repeat center fixed;
    background-size: cover;
    padding: 18vh 15vh 18vh 15vh;
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #fff;
}

.tech-stack-number{
    font-weight: 600;
    font-size: 100px;
    line-height: 150px;
}

.position-details{
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #3C6AEA;
    }

.title-margin-porto{
    margin-bottom: 2vh;
    margin-top: 4vh;
}

.margin-tech-stack{
    margin: 0;
    padding: 0;
}

.margin-top-progress{
    margin-top: 0;
}


.progress-bar{
    color: #3C6AEA;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 700;
font-size: 18px;
line-height: 32px;
}

.junior{
    display: inline-block;
}

.senior{
    display: inline-block;
}

.position-progress{
}

.progress-progress{
    width: 100%;
    align-items: center;
}



/* 

@media(max-width: 576px){
    .portfolio-details-hero{
        padding: 25vh 5vh 20vh 5vh;
    }
    .details-image-place{
        margin-left: 0;
    }
    .portfoliodetails-section1{
        padding: 10vh 5vh 10vh 5vh;
    }
    .portfoliodetails-section2{
        padding: 10vh 5vh 10vh 5vh;
    }
    .portfoliodetails-section3{
        padding: 10vh 5vh 10vh 5vh;
    }
    .control-button-next .control-button-title,
    .control-button-next .client-control-name,
    .control-button-next {
    margin-left: 2vh;
    }
    .banner-tech-stack{
        padding: 18vh 5vh 18vh 5vh;
        text-align: left;
    }

    .title-margin-porto{
        margin-bottom: 1vh;
        margin-top: 6vh;
    }
    .position-details{
        font-weight: 600;
        font-size: 20px;
        line-height: 50px;
    }
    .tech-stack-number{
        font-size: 70px;
        line-height: 50px;
    }


}

@media(min-width: 576px){
    .portfolio-details-hero{
        padding: 25vh 5vh 20vh 5vh;
    }
    .details-image-place{
        margin-left: 0;
    }
    .portfoliodetails-section1{
        padding: 10vh 5vh 10vh 5vh;
    }
    .portfoliodetails-section2{
        padding: 10vh 5vh 10vh 5vh;
    }
    .portfoliodetails-section3{
        padding: 10vh 5vh 10vh 5vh;
    }
    .banner-tech-stack{
        padding: 18vh 5vh 18vh 5vh;
        text-align: left;
    }

    .title-margin-porto{
        margin-bottom: 1vh;
        margin-top: 6vh;
    }
    .position-details{
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
    }
    .tech-stack-number{
        font-size: 70px;
        line-height: 50px;
    }

}

@media(min-width: 768px){
    .banner-tech-stack{
        padding: 10vh 5vh 15vh 5vh;
        text-align: left;
    }

    .title-margin-porto{
        margin-bottom: 1vh;
        margin-top: 6vh;
    }
    .position-details{
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
    }
    .tech-stack-number{
        font-size: 70px;
        line-height: 50px;
    }
}

@media(min-width:992px){
    .margin-tech-stack{
        margin-left: 0;
    }
}

@media(min-width:1200px) {
    .portfolio-details-hero{
        padding: 25vh 15vh 20vh 15vh;
        text-align: center;
        background-color: #f0f3ff;
    }

    .portfolio-details-link{
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: #3C6AEA;
    }

    .link-title{
        font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #acacac;
    }

    .details-image-place{
        width: 90%;
        object-fit: cover;
        margin-top: 5vh;
        margin-left: 5vh;
    }

    .details-image{
        max-width: 100%;
    }

    .portfoliodetails-title{
        margin-top: 3vh;
        font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #303030;
    }

    .portfoliodetails-section1{
        background-color: #f9fbfe;
        padding: 10vh 15vh 10vh 15vh;
    }

    .portodetails-section1-body{
        font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #303030;
    }

    .portodetails-section1-industry{
        font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #3C6AEA;
    }

    .portodetails-section1-text{
        font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6E6E6E;
    margin-top: 3vh;
    margin-bottom: 3vh;
    }

    .deliv{
        font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6E6E6E;
    margin-top: 2vh;
    }

    .portfoliodetails-section2{
        padding: 10vh 15vh 10vh 15vh;
        background-color: #101010;

    }

    .portodetails-section2-title{
        font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #fff;
    margin-bottom: 3vh;
    }

    .circle{
        border: 10px solid #3C6AEA;
    background-color: #101010;
        border-radius: 50%;
        height: 20vh;
        width: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
    font-size: 50px;
    line-height: 75px;
    color: #fff;
    }

    .project-summary-title{
        font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #fff;
    }

    .project-summary-text{
        font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    margin-top: 3vh;
    }

    .details-year{
        font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    margin-top: 2vh;
    }

    .details-platform{
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        color: #fff;
        margin-top: 2vh;

    }

    .portfoliodetails-section3{
        padding: 10vh 15vh 10vh 15vh;
        background-color: #f0feff;
        text-align: center;
    }

    .portdetails-section3-sub{
        font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #4070FF;
    }

    .portdetails-section3-title{
        font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #303030;
    margin-bottom: 4vh;
    }

    .scope-place{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .scope-circle{
        background-color: #acacac;
        border-radius: 50%;
        height: 15vh;
        width: 15vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .platform-image{
    width: 10%;
    background-color: #fff;
    border-radius: 50%;
    }
    .client-logo{
        width: 80%;
    }

    .control-button-place{
    background-color: #f9fbfe;
    height: auto;
    display: flex;
}


.control-button-next{
    padding: 3vh 0 3vh 0 ;
    display: inline-block;
    width: 50%;

}


.control-button-prev{
    padding: 3vh 0 3vh 0 ;
    display: inline-block;
    width: 50%;
    transition: all 0.8s;
}

.control-button-title{
    font-size: 24px;
    line-height: 50px;
    color: #ACACAC;
    font-weight: 600;
    margin-left: 4vh;
    transition: all 0.8s;
}

.client-control-name{
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    color: #303030;
    margin-left: 4vh;
    transition: all 0.8s;
}

.button-placement-control-left{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 13vh;
    transition: all 0.8s;
}

.button-placement-control-right{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 13vh;
    transition: all 0.8s;
}

.arrow-color{
    color: #303030;
    font-size: 5vh;
}

.button-margin{
    margin-left: 10vh;
}

.banner-tech-stack{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url(/src/assets/banner_2.png) no-repeat center fixed;
    background-size: cover;
    padding: 18vh 15vh 18vh 15vh;
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #fff;
}

.tech-stack-number{
    font-weight: 600;
    font-size: 100px;
    line-height: 150px;
}

.position-details{
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #fff;
    }

.title-margin-porto{
    margin-bottom: 4vh;
}

.margin-tech-stack{
    margin: 0;
    padding: 0;
}

.margin-top-progress{
    margin-top: 12vh;
}


} */
